import { useState, useEffect } from 'react';
import '../assets/scss/pages/Hero.scss';
import profile from '../img/profile.png';
import profile2 from '../img/profile2.png';
import AOS from 'aos';
import exampleVideo from '../videos/bandung.mp4';
import blackDoor from '../img/black-door.png';

const Hero = () => {
	const [scrollingDown, setScrollingDown] = useState(true);
	const [isBouncing, setIsBouncing] = useState(false);
	const [showFirstMotto, setShowFirstMotto] = useState(true);

	useEffect(() => {
		AOS.init({ duration: 2000, once: false });

		const interval = setInterval(() => {
			setShowFirstMotto((prev) => !prev);
			AOS.refresh();
		}, 5000);

		return () => clearInterval(interval);
	}, []);

	const handleScroll = () => {
		const heroSectionHeight = document.querySelector('.hero').offsetHeight;
		const heroNameSectionHeight = document.querySelector('.hero__name').offsetHeight;


		setIsBouncing(true);
		setTimeout(() => setIsBouncing(false), 1500);

		if (scrollingDown) {
			window.scrollTo({
				top: heroSectionHeight + heroNameSectionHeight,
				behavior: 'smooth',
			});
		} else {
			window.scrollTo({
				top: 0,
				behavior: 'smooth',
			});
		}

		setScrollingDown(!scrollingDown);
	};

	useEffect(() => {
		const handleScrollEvent = () => {
			const heroSectionHeight =
				document.querySelector('.hero').offsetHeight;
			const heroNameSectionHeight =
				document.querySelector('.hero__name').offsetHeight;
			const scrollPosition = window.scrollY;

			if (scrollPosition >= (heroSectionHeight + heroNameSectionHeight)) {
				setScrollingDown(false);
			} else {
				setScrollingDown(true);
			}
		};
		window.addEventListener('scroll', handleScrollEvent);

		return () => {
			window.removeEventListener('scroll', handleScrollEvent);
		};
	}, []);

	return (
		<section className='hero'>
			<div className="hero__circle">
				<svg
					// className='hero__circle'
					viewBox='0 0 500 500'
					data-aos={'fade-down'}
					data-aos-offset='10'
				>
					<circle
						cx='250'
						cy='250'
						r='200'
						stroke='white'
						strokeWidth='1'
						fill='none'
					/>
				</svg>
			</div>

			<div className="hero__blackDoor">
				<img
					src={blackDoor}
					alt="Black Door"
					className='blackDoor'
					data-aos={'fade-left'}
					data-aos-offset='10'
				/>
			</div>

			<video
				autoPlay
				loop
				muted
				className='hero__video'
				data-aos={'fade-up'}
				data-aos-offset='10'
			>
				<source src={exampleVideo} type='video/mp4' />
			</video>

			<div className='banner__profile'>
				<div className='banner__profile--first'
					data-aos={'fade-right'}
					data-aos-offset='10'
				>
					<h2
						className={`motto ${showFirstMotto ? 'show' : ''}`}
					>
						My Lens,<br />
						Your Vision,<br />
						One Powerful Story.
					</h2>
				</div>

				<div className='banner__profile--second'>
					<h2
						className={`motto2 ${!showFirstMotto ? 'show' : ''}`}
						// data-aos={showFirstMotto ? 'fade-out' : 'fade-right'}
						// data-aos-offset='10'
					>
						Scroll Down<br />
						to Explore<br />
						My Creative Journey.
					</h2>
				</div>
			</div>

			{/* <div className="hero__name">
				<svg viewBox="0 0 500 100">
					<text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle"
						fontSize="48" fontWeight="bold" stroke="white" strokeWidth="1" fill="none">
						RIKI NOVAL SUHERMAN
					</text>
				</svg>
			</div> */}

			<div className='scroll__down'>
				<span
					className={`scroll__down--button animate__animated ${
						isBouncing ? 'animate__bounce' : ''
					}`}
					onClick={handleScroll}
				>
					<i
						className={`fa-solid ${
							scrollingDown ? 'fa-arrow-down' : 'fa-arrow-up'
						} fa-2xl`}
					></i>
				</span>
			</div>
		</section>
	);
};
export default Hero;
