import Marquee from "react-fast-marquee";
import AOS from 'aos';
import '../assets/scss/pages/HeroName.scss';

const HeroName = () => {
	return (
		<section className='hero__name'>
            <Marquee speed={50} gradient={false}>
                <h1 className='fullname'>RIKI NOVAL SUHERMAN</h1>
            </Marquee>
		</section>
	);
};
export default HeroName;
