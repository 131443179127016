import '@fancyapps/ui/dist/fancybox/fancybox.css';
import { Hero, Works } from '../pages';
import HeroName from '../components/HeroName';

function App() {
	return (
		<>
			<Hero />
			<HeroName />
			<Works />
		</>
	);
}

export default App;
